.languageBlock {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}

.languageBlock {
    color: #000;
    font-size: 12px;
    line-height: 130%;
    font-family: 'Poppins', sans-serif;
}

.languageBlock p:first-child {
    text-transform: uppercase;
    width: 20px;
}

.languageBlock img {
    width: 16px;
}

.selectedLanguageText {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    line-height: 130%;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
}

.languageBlock:last-child {
    margin-bottom: 0;
}