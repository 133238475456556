@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=League+Spartan:wght@500&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=League+Spartan:wght@500&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=League+Spartan:wght@500&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=League+Spartan:wght@500&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Playwrite+CU:wght@100..400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.playwrite {
  font-family: "Playwrite CU", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.hind {
  font-family: "Hind", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.lato {
  font-family: "Lato", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.oswald {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.shadowTeachers {
  box-shadow: 0px 2px 18px 0px rgba(0, 6, 61, 0.15),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.shadowBtnInfluencer {
  box-shadow: 0px 3.925px 7.851px 0px rgba(0, 0, 0, 0.15);
}

.shadowBlockInfluencer {
  box-shadow: 0px 5.193px 5.193px 0px rgba(0, 0, 0, 0.25);
}

.shadowBlockMonetize {
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.15);
}

.shadowBtnHost {
  box-shadow: 0px 0px 7.851px 1.963px rgba(0, 0, 0, 0.3);
}

.circle-text {
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-text span {
  position: absolute;
  transform-origin: center center;
}

.circle-text span:nth-child(1) {
  transform: rotate(0deg) translateX(100px);
}

.circle-text span:nth-child(2) {
  transform: rotate(15deg) translateX(100px);
}

.circle-text span:nth-child(3) {
  transform: rotate(30deg) translateX(100px);
}

.bm-menu-wrap {
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
}

.bm-item-list {
  background-image: url(./Images/menuBg.png) !important;
  z-index: 9999 !important;
  position: relative !important;
  background-size: cover !important;
}

.bm-overlay {
  top: 0 !important;
}

.menuOpen {
  position: absolute !important;
  top: 30px !important;
  padding-right: 20px !important;
}

.bm-burger-button {
  display: none !important;
}

.menu {
  position: relative !important;

  z-index: 100 !important;
}

.homeBtn {
  background: var(--Gradient, linear-gradient(0deg, #8649d1 0%, #8f92ff 100%));
}

.createBg {
  background: var(--Gradient,
      linear-gradient(0deg, #8b6ee9 0%, #8e82f5 39%, #6d64aa 100%));
}

.hostBg {
  background: linear-gradient(0deg, #e99701 0%, #f6b800 50%, #f9c642 100%);
}

.homeBtnGradient {
  background: var(--Gradient, linear-gradient(0deg, #8649d1 0%, #8f92ff 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (min-width: 768px) {
  .menu {
    z-index: 100 !important;
  }
}

@media screen and (min-width: 768px) {
  .bm-menu-wrap {
    width: 400px !important;
  }
}

.swiper-pagination {
  top: 0 !important;
}

.swiper-pagination-bullet {
  background-color: white !important;
  width: 7% !important;
  height: 4px !important;
  border-radius: 3px !important;
}

.active-tab:before {
  content: "";
  position: absolute;
  top: -10px;
  rotate: -360deg;
  right: 0px;
  width: 10px;
  height: 10px;
  background-image: url("./Images/rounded-top.svg");
  background-size: cover;
}

.active-tab::after {
  content: "";
  position: absolute;
  bottom: -10px;
  rotate: -90deg;
  right: 0px;
  width: 10px;
  height: 10px;
  background-image: url("./Images/rounded-top.svg");
  background-size: cover;
}

.active-tab-last::after {
  bottom: 0px !important;
  rotate: 90deg !important;
  right: -15px;
  width: 30px;
  height: 30px;
  background: white;
}

.active-tab-first::before {
  display: none;
}

.hostTabBg {
  background: linear-gradient(180deg, #8649D1 0%, #7200FF 100%);
}

.createGradient {
  background: linear-gradient(0deg, #8649D1 0%, #8B6DE8 38.54%, #8F92FF 100%);
}

.homeGradientBg {
  background: linear-gradient(180deg, #8649D1 0%, #7200FF 100%);
}

.background-transition {
  transition: background 1s ease-in-out, background-position 1s ease-in-out, background-size 1s ease-in-out;
}

.dimmed-slide {
  position: relative;
  filter: brightness(0.5);
}

@media screen and (max-width: 768px) {
  .dimmed-slide {
    filter: brightness(1);
  }

  .swiper-pagination-bullet {

    width: 12% !important;

  }
}

.shadowSlideHome {
  box-shadow: 4px 2px 8px 0px #2F2F2F66;
}

.menuSelect {
  padding: 2px 4px 2px 4px !important;
}

.gradientAdvantages {
  background: linear-gradient(180deg, #FFFFFF 0%, #E7D3FF 100%);
}

.slick-list {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 30px !important;
}

.hostHomeBtnShadow {
  box-shadow: 0px 0px 4px 0px rgba(34, 33, 57, 0.40);
}

.hostContentBlockShadow {
  box-shadow: 0px 0px 8px 0px #FEB80B;
  border: 3px solid #FFFFFF
}

.getVideoLineGradient {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, #FFFFFF 49.5%, rgba(255, 255, 255, 0.2) 100%);
}

.whatGradient {
  background: linear-gradient(180deg, #B5A0FF 0%, #6447CC 100%);
}

.payMethodShadow {
  box-shadow: 0px 4px 4px 0px rgba(175, 175, 175, 0.25);
}

.brendStatsShadow {
  box-shadow: 0px 3.82px 9.55px 0px rgba(0, 0, 0, 0.15);

}

.brendComentsShadow:hover {
  box-shadow: 0px 0px 10px 0px #C049FF;
}

.brendComentsShadow:hover .brendComentsText {
  color: white;
}

.brendComentsShadow:hover .brendComentsText2 {
  color: white;
  opacity: 60%;
}

.brendComentsShadow:hover .brendComentsText3 {
  color: white;
  opacity: 80%;
}

.brendComentsShadow:hover .brendComentsDot {
  background: white;
  opacity: 60%;
}

.forBold b {
  font-weight: 600;
}

.heightForSafari {
  height: calc(100vh - 100px);
}

@supports (-webkit-touch-callout: none) {
  .heightForSafari {
    height: calc(100vh - 50px);
  }
}

@media screen and (max-height: 850px) and (min-width: 1200px) {
  .mtForMac {
    margin-top: -50px;
  }

  .mbForMac {
    margin-bottom: 80px;
  }
}

@media screen and (max-height: 770px) and (min-width: 1200px) {
  .mtForMac2 {
    margin-top: -20px;
  }

  .mbForMac {
    margin-bottom: 200px;
    line-height: 80px;
  }
}

.shadow {
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.50);
}