.menuSelect {
  padding: 2px 5px 2px 5px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid #dee5ef;
  background: white;
  display: flex;
  gap: 5px;
  cursor: pointer;
  max-width: 64px;
  align-items: center;

}

.menu {
  z-index: 99999;
  display: block;
  max-width: 150px;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}


.menuOpen {
  display: grid;
  position: absolute;
  top: 40px;
  grid-template-columns: repeat(1, 1fr);
  flex-direction: column;
  gap: 5px;
  background: white;
  border-radius: 4px;
  border: 1px solid #dee5ef;
  padding: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
}

.langItem {
  cursor: pointer;
  padding: 5px;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: 130%;
  color: #394a64;
  text-transform: uppercase;
}

@media (max-width: 768px) {
 

  .menuOpen {
    top: 40px;
  }
}

.langItem:hover {
  background: #f5f7fa;
}

.selectedLanguage {
  color: #000;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: 130%;
}

.menuOpenWrapper {
  height: 100%;
}

.menuOpen {
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.menuOpen::-webkit-scrollbar {
  width: 5px;
}

/* width */

.menuOpen::-webkit-scrollbar {
  width: 5px;
}

/* Track */

.menuOpen::-webkit-scrollbar-track {
  background: white;
}

/* Handle */

.menuOpen::-webkit-scrollbar-thumb {
  background: #394a64;
}

/* Handle on hover */

.menuOpen::-webkit-scrollbar-thumb:hover {
  background: #394a64;
}